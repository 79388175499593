import React, { useState } from 'react';

import { useTranslation } from '../../I18nProvider';
import * as api from '../../utils/api';
import { getErrorMessage, isStatus } from '../../utils/api';
import { Button } from '../common/Button';
import { QRCodeIcon } from '../common/Icons';
import { Row } from '../common/Row';
import { Stack } from '../common/Stack';
import { ErrorMessage } from '../form/ErrorMessage';
import { Input } from '../form/Input';
import { extractBoxId } from './extractBoxId';
import { QRCodeScanner } from './QRCodeScanner';

type Props = {
  onLink: (data: { box_id: string }) => any;
};
export function LinkBox({ onLink }: Props) {
  const t = useTranslation();
  const [boxId, setBoxId] = useState('');
  const [showScanner, setShowScanner] = useState(false);
  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState(false);
  const link = async (box_id: string) => {
    setSubmitting(true);
    try {
      const box = await api.getBox(box_id);
      if (box.lift) {
        if (
          !confirm(
            t.boxLinkedToOtherLift(box.lift.name, box.lift.building.name)
          )
        ) {
          setSubmitting(false);
          return;
        }
      }
    } catch (err) {
      if (isStatus(err, 403)) {
        setError(getErrorMessage(err));
        setSubmitting(false);
      }
      // other errors are ignored because they are unexpected and should therefore trigger the ErrorBoundary
    }

    try {
      await onLink({ box_id });
      setError(undefined);
    } catch (err: any) {
      setError(getErrorMessage(err));
    }
    setSubmitting(false);
  };
  return (
    <Stack>
      <p>{t.noBoxLinked}</p>
      {!error && showScanner && (
        <QRCodeScanner
          onCancel={() => setShowScanner(false)}
          onScan={(text) => {
            const box_id = extractBoxId(text);
            if (box_id) {
              setBoxId(box_id);
              setShowScanner(false);
              link(box_id);
            }
          }}
        />
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Row>
        <Input
          style={{ flexGrow: 1 }}
          value={boxId}
          aria-invalid={!!error}
          onChange={(ev) => {
            setBoxId(ev.target.value);
            setError(undefined);
          }}
          placeholder={t.box_id}
        />
        <Button
          type="button"
          variant="secondary"
          onClick={() => {
            setError(undefined);
            setShowScanner(true);
          }}
        >
          {t.scan} <QRCodeIcon />
        </Button>
      </Row>
      <Button
        type="button"
        variant="primary"
        onClick={() => link(boxId)}
        disabled={submitting || !boxId}
      >
        {t.linkBox}
      </Button>
    </Stack>
  );
}
