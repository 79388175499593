import React from 'react';
import styled from 'styled-components';

import { Button } from '../../components/common/Button';
import {
  Content,
  IconProps,
  StyledCard,
  Title,
} from '../../components/common/Card';
import { Spacer } from '../../components/common/Spacer';
import { Stack } from '../../components/common/Stack';
import { CardSubTitle, Overline } from '../../components/common/typography';
import { borderRadius, color, space } from '../../tokens';

const Icon = styled.div<{
  selectedColor?: string;
}>`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.selectedColor === 'warning'
      ? color.warning
      : props.selectedColor === 'error'
      ? color.error
      : color.success};
  color: ${color.textOnPrimary};
  padding: ${space['2']};
  border-radius: 0 ${borderRadius.default} ${borderRadius.default} 0;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DropdownContainer = styled.div`
  flex: 1;
`;

interface DropdownOption {
  value: string;
  label: string;
}

interface ComponentProps extends IconProps {
  button?: {
    text: string;
    action: () => void;
  };
  dropdown: {
    label: string;
    value: string;
    onChange: (value: string) => void;
    options: DropdownOption[];
  };
  children?: React.ReactNode;
}

export function SoftwareUpdateCard({
  icon,
  title,
  subTitle,
  to,
  disabled,
  status,
  button,
  dropdown,
  children,
  ...rest
}: ComponentProps) {
  return (
    <StyledCard to={to} {...rest} disabled={disabled} status={status}>
      <Content>
        <Container>
          <Stack gap={space[2]}>
            <Title>
              {title}
              {subTitle && <CardSubTitle>{subTitle}</CardSubTitle>}
            </Title>
            {children && <Overline>{children}</Overline>}
            <Spacer padding={space[1]} />
          </Stack>
          <Stack>
            {/* 
            TODO: To show update modus dropdown, uncomment this part
            Feature: Hide auto update dropdown (WEAR-66)
          */}
            {/* <DropdownContainer>
              <Dropdown
                label={dropdown.label}
                value={dropdown.value}
                onChange={(e) => dropdown.onChange(e.target.value)}
                dropdownSize="s"
              >
                {dropdown.options.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.label}
                  </option>
                ))}
              </Dropdown>
            </DropdownContainer> */}
          </Stack>
        </Container>
        <Spacer padding={space[1]} />
        <Stack>
          {button?.text && (
            <Button
              variant="secondary"
              size="xs"
              minWidth="small"
              onClick={button.action}
            >
              {button.text}
            </Button>
          )}
        </Stack>
      </Content>
      {icon && <Icon>{icon}</Icon>}
    </StyledCard>
  );
}
