import React from 'react';

import { Button } from '../components/common/Button';
import { FullScreenDialog } from '../components/common/FullScreenDialog';
import { Logo } from '../components/common/Logo';
import { Row } from '../components/common/Row';
import { Stack } from '../components/common/Stack';
import { TinyLink } from '../components/common/typography';
import { Form } from '../components/form/Form';
import { InputField } from '../components/form/InputField';
import { useFormSubmission } from '../hooks/useFormSubmission';
import { useTranslation } from '../I18nProvider';
import { space } from '../tokens';
import { useSetLogin } from '../UserProvider';
import { login, LoginCredentials } from '../utils/api';

export function Login() {
  const setLogin = useSetLogin();
  const t = useTranslation();
  const { register, form } = useFormSubmission<LoginCredentials>({
    async onSubmit(credentials) {
      const result = await login(credentials);
      setLogin(result);
    },
  });

  return (
    <FullScreenDialog>
      <Logo />
      <Form {...form}>
        <Stack>
          <p>{t.loginText}</p>
          <InputField
            register={register}
            name="email"
            type="email"
            placeholder={t.email}
            autoFocus
            required
          />
          <Stack gap={space[1]}>
            <Row justify="end">
              <TinyLink to="/forgot-password">{t.forgotPassword}</TinyLink>
            </Row>
            <InputField
              register={register}
              name="password"
              type="password"
              placeholder={t.password}
              required
            />
          </Stack>
          <Button variant="primary">{t.login}</Button>
        </Stack>
      </Form>
    </FullScreenDialog>
  );
}
