import { upperFirst } from 'lodash';
import React, { ReactElement } from 'react';
import { get, useFormContext } from 'react-hook-form';

import { useTranslation } from '../../I18nProvider';
import { space } from '../../tokens';
import { Stack } from '../common/Stack';
import { ErrorMessage } from './ErrorMessage';

type Props = {
  name: string;
  children: ReactElement;
  label?: string;
  required?: boolean | string;
  error?: string;
  hasError?: boolean;
};

export function FormField({
  name,
  label,
  required,
  children,
  hasError,
}: Props) {
  const t = useTranslation();

  const defaultMessage = (type = 'invalid') => {
    const key = `error${upperFirst(type)}`;
    return (t as any)[key];
  };

  const {
    formState: { errors },
  } = useFormContext();
  const error = get(errors, name);

  return (
    <Stack gap={space[2]}>
      {label && <div data-required={!!required}>{label}</div>}
      {children}
      {error && (
        <ErrorMessage>
          {error.message || defaultMessage(error.type)}
        </ErrorMessage>
      )}
      {hasError && (
        <ErrorMessage>{defaultMessage('InvalidOperation')}</ErrorMessage>
      )}
    </Stack>
  );
}
