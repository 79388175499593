import React, { useState } from 'react';
import styled from 'styled-components';

import { Hint } from '../../components/common/Hint';
import { Loading } from '../../components/common/Loading';
import { Page } from '../../components/common/Page';
import { Stack } from '../../components/common/Stack';
import { Dropdown } from '../../components/form/Dropdown';
import { useApiMutation, useApiQuery } from '../../hooks/useApi';
import { useTranslation } from '../../I18nProvider';
import { BoxSoftwareStatus } from '../../utils/appApi';
import { SoftwareUpdateCard } from './SoftwareUpdateCard';

enum SortingOption {
  SortByUpdateStatus = 'sortByUpdateStatus',
  SortByBoxId = 'sortByBoxId',
}

const LineContainer = styled.div``;

const Line = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  width: 50%;
`;

const isUpdateNeeded = (
  currentVersionOfBox: string,
  latestSoftwarePackVersion: string
): boolean => {
  return currentVersionOfBox !== latestSoftwarePackVersion;
};

export function BoxSoftware() {
  const t = useTranslation();
  const [sortBy, setSortBy] = useState(SortingOption.SortByUpdateStatus);
  const [sortingUpdateModes, setSortingUpdateModes] = useState<{
    [boxId: string]: string;
  }>({});
  const [updatingBoxes, setUpdatingBoxes] = useState<Set<string>>(new Set());

  const queryData = useApiQuery('getBoxSoftwareStatuses');

  const sortBoxSoftwareStatuses = (
    statuses: BoxSoftwareStatus[],
    sortingOption: SortingOption
  ) => {
    const latestSoftwarePackVersion =
      queryData.data?.latestSoftwarePackVersion || '';
    const boxesWithOutdatedVersion = statuses.filter(
      (item) => item.currentSoftwarePackVersion !== latestSoftwarePackVersion
    );
    const boxesWithLatestVersion = statuses.filter(
      (item) => item.currentSoftwarePackVersion === latestSoftwarePackVersion
    );

    if (sortingOption === SortingOption.SortByUpdateStatus) {
      boxesWithOutdatedVersion.sort((a, b) =>
        a.currentSoftwarePackVersion.localeCompare(b.currentSoftwarePackVersion)
      );
      return boxesWithOutdatedVersion.concat(boxesWithLatestVersion);
    } else {
      boxesWithLatestVersion.sort((a, b) => a.boxId.localeCompare(b.boxId));
      return boxesWithLatestVersion.concat(boxesWithOutdatedVersion);
    }
  };

  const handleSortByChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSortBy(e.target.value as SortingOption);
  };

  const changeUpdateMode = useApiMutation('changeUpdateMode', {
    onSuccess: () => {
      queryData.refetch();
    },
  });

  const updateSoftware = useApiMutation('updateVersion', {
    onSuccess: () => {
      queryData.refetch();
      // Reload to show status results
      window.location.reload();
    },
  });

  const handleUpdateSoftware = async (boxId: string) => {
    setUpdatingBoxes((prev) => new Set(prev).add(boxId));
    try {
      await updateSoftware(boxId);
    } finally {
      setUpdatingBoxes((prev) => {
        const newSet = new Set(prev);
        newSet.delete(boxId);
        return newSet;
      });
    }
  };

  const createBoxList = (list: BoxSoftwareStatus[], isOnline: boolean) => {
    if (!list) {
      return [];
    }

    const handleSortingUpdateModeChange = async (
      boxId: string,
      updateMode: string
    ) => {
      await changeUpdateMode({ boxId, updateMode });
      setSortingUpdateModes((prevModes) => ({
        ...prevModes,
        [boxId]: updateMode,
      }));
    };

    const sortedList = sortBoxSoftwareStatuses(list, sortBy);

    return sortedList.map((status) => {
      const {
        boxId,
        isOnline,
        currentSoftwarePackVersion,
        updateMode,
        isUpdateRunning,
      } = status;

      const sortingUpdateMode = sortingUpdateModes[boxId] || updateMode;
      const updateModeSortingOptions = [
        { value: 'auto', label: t.auto },
        { value: 'manual', label: t.manual },
      ];

      const updateRequired = isUpdateNeeded(
        currentSoftwarePackVersion,
        queryData.data?.latestSoftwarePackVersion || ''
      );

      const isUpdating = updatingBoxes.has(boxId);

      return (
        <SoftwareUpdateCard
          key={boxId}
          title={boxId}
          to={''}
          status={updateRequired ? 'error' : 'success'}
          disabled={!isOnline || isUpdating || queryData.isLoading}
          button={{
            text:
              updateRequired && isOnline && !isUpdating && !queryData.isLoading
                ? t.update
                : isUpdating || queryData.isLoading || isUpdateRunning
                ? t.updating
                : '',
            action: () => handleUpdateSoftware(boxId),
          }}
          dropdown={{
            label: t.mode,
            value: sortingUpdateMode,
            onChange: (value: string) =>
              handleSortingUpdateModeChange(boxId, value),
            options: updateModeSortingOptions,
          }}
        >
          {isUpdating || queryData.isLoading || isUpdateRunning ? (
            <></>
          ) : (
            currentSoftwarePackVersion &&
            `${t.currentVersion} ${currentSoftwarePackVersion}`
          )}
        </SoftwareUpdateCard>
      );
    });
  };

  const onlineBoxes =
    queryData.data?.boxSoftwareStatuses?.filter((status) => status.isOnline) ||
    [];
  const offlineBoxes =
    queryData.data?.boxSoftwareStatuses?.filter((status) => !status.isOnline) ||
    [];

  const mainSortingOptions = [
    { value: SortingOption.SortByUpdateStatus, label: t.updateRequired },
    { value: SortingOption.SortByBoxId, label: t.upToDate },
  ];

  return (
    <Page
      backLink="/"
      pageTitle={t.boxSoftwareTitle}
      subHeadline={t.latestSoftwarePackVersion(
        queryData.data?.latestSoftwarePackVersion || ''
      )}
    >
      {queryData.isLoading && <Loading />}
      {!queryData.isLoading && (
        <Stack>
          <DropdownContainer>
            <Dropdown
              label={t.mode}
              value={sortBy}
              onChange={handleSortByChange}
            >
              {mainSortingOptions.map((option) => (
                <option value={option.value} key={option.value}>
                  {option.label}
                </option>
              ))}
            </Dropdown>
          </DropdownContainer>
          <LineContainer>
            <h4>{t.online}</h4>
            <Line />
          </LineContainer>
          {createBoxList(onlineBoxes, true)}
          <LineContainer>
            <h4>{t.offline}</h4>
            <Line />
          </LineContainer>
          <Hint>{t.boxOfflineHint}</Hint>
          {createBoxList(offlineBoxes, false)}
        </Stack>
      )}
    </Page>
  );
}
