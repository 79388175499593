import React, { useEffect, useState } from 'react';
import BarcodeScannerComponent from 'react-barcode-scanner-webcam';
import styled from 'styled-components';

import { useTranslation } from '../../I18nProvider';
import { color, fontSize } from '../../tokens';
import { Overlay } from '../common/Overlay';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ScannedTextBox = styled.div`
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  background-color: rgba(0, 0, 0, 0.85);
  color: ${color.background};
  padding: 20px;
  width: 80%;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const Button = styled.button`
  background-color: ${color.primary};
  color: ${color.background};
  border: none;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${color.primary};
  }
`;

const NoQRCodeMessage = styled.div`
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${color.background};
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 10px;
  font-size: ${fontSize.base};
  text-align: center;
`;

const NO_QR_CODE_TIMEOUT_MS = 3000;
enum QRCodeType {
  WIFI = 'WIFI',
  PC = 'PC',
}

type Props = {
  onScan: (text: string) => void;
  onCancel: () => void;
};

export function QRCodeScanner({ onScan, onCancel }: Props) {
  const t = useTranslation();
  const [scannedText, setScannedText] = useState<string | null>(null);
  const [displayText, setDisplayText] = useState<string | null>(null);
  const [noQRCode, setNoQRCode] = useState<boolean>(false);

  useEffect(() => {
    if (!scannedText) {
      const timer = setTimeout(() => {
        setNoQRCode(true);
      }, NO_QR_CODE_TIMEOUT_MS);

      return () => clearTimeout(timer);
    } else {
      setNoQRCode(false);
    }
  }, [scannedText]);

  const handleScan = (text: string) => {
    /**
     * - **WIFI Pattern:** Text starting with `WIFI` indicates Wi-Fi network details (e.g., `WIFI:T:WPA;S:SSID;P:password;H:;; `).
     * - **Box Pattern:** Text starting with `PC` indicates a box with an ID (e.g., `PC:10745658A01;HW:0003;NM:WTMS2407230048;CH:AAAABDB;`).
     * - **Default:** For other texts, it sets a generic scanned message.
     */
    let message = '';
    if (text.startsWith(QRCodeType.WIFI)) {
      message = t.wifiScanned;
    } else if (text.startsWith(QRCodeType.PC)) {
      // Extracting the box ID from a string that starts with 'PC' and includes 'NM:<boxid>;'
      const match = text.match(/NM:(.*?);/);
      if (match && match[1]) {
        message = `${t.followingBoxScanned}: ${match[1]}`;
      } else {
        message = t.boxScannedNoIdFound;
      }
    } else {
      message = `${t.scanned}: ${text}`;
    }
    setDisplayText(message);
    setScannedText(text);
  };

  const resetScanner = () => {
    setScannedText(null);
    setDisplayText(null);
  };

  return (
    <Overlay onClose={onCancel}>
      <Wrapper>
        <BarcodeScannerComponent
          width="100%"
          height="400px"
          facingMode="environment"
          onUpdate={(_err, result) => {
            if (result) {
              const text = result.getText();
              handleScan(text);
            }
          }}
        />
        {displayText && (
          <ScannedTextBox>
            <p>{displayText}</p>
            {!displayText.startsWith(QRCodeType.WIFI) && (
              <Button onClick={() => onScan(scannedText!)}>{t.confirm}</Button>
            )}
            <Button onClick={resetScanner}>{t.scanAgain}</Button>
          </ScannedTextBox>
        )}
        {!scannedText && noQRCode && (
          <NoQRCodeMessage>{t.noQRCodeDetected}</NoQRCodeMessage>
        )}
      </Wrapper>
    </Overlay>
  );
}
