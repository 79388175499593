import merge from 'lodash/merge';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { Card } from '../components/common/Card';
import { IconButton } from '../components/common/IconButton';
import {
  ChartIcon,
  DialPadIcon,
  EditIcon,
  PreventiveMaintenanceIcon,
  RemoteIcon,
} from '../components/common/Icons';
import { Loading } from '../components/common/Loading';
import { Page } from '../components/common/Page';
import { Stack } from '../components/common/Stack';
import { DataGrid } from '../components/DataGrid';
import { LiftHeader } from '../components/lift/LiftHeader';
import { LiftSetupStatus } from '../components/lift/LiftSetupStatus';
import { LinkBox } from '../components/lift/LinkBox';
import { useApiMutation, useApiQuery } from '../hooks/useApi';
import { useTranslation } from '../I18nProvider';
import { space } from '../tokens';
import { FullLift } from '../utils/appApi';

export function Lift() {
  const params = useParams();
  const id = parseInt(params.id!);
  const liftQuery = useApiQuery('getLift', id);
  const productQuery = useApiQuery('getProductStatus', id);
  const updateLift = useApiMutation('updateLift', {
    onSuccess: (_result, [id, data]) => {
      liftQuery.update((old: FullLift | undefined) => merge({}, old, data));
    },
  });
  const t = useTranslation();

  const { box_id, assetNumber, box, floors } = liftQuery.data || {};
  const static_data: any = box?.static_data ?? {};
  const { driveesn, drivesoftware, ipaddress, doorwidth, couplerwidth } =
    static_data;

  const hasICTRLicense = !!productQuery.data?.INST_CTRL;
  const hasRTLicense = !!productQuery.data?.RT_DIAG;
  const hasPRGLicense = !!productQuery.data?.PRG_TOOL;
  const hasPREVLicense = !!productQuery.data?.PREV_MAIN;

  const summary = {
    box_id,
    assetNumber,
    floors,
    driveesn,
    drivesoftware,
    ipaddress,
    doorwidth,
    couplerwidth,
  };

  return (
    <Page
      header={
        <LiftHeader
          id={id}
          action={
            <IconButton as={Link} to="edit">
              <EditIcon />
            </IconButton>
          }
        />
      }
      pageTitle={t.lift}
      backLink={`/building/${params.buildingId}`}
    >
      {liftQuery.isLoading && <Loading />}
      {liftQuery.isSuccess && (
        <Stack gap={space[4]}>
          {(hasRTLicense || hasPREVLicense) && (
            <LiftSetupStatus id={id} linkTo={'./setup'} />
          )}
          {box_id ? (
            <>
              <DataGrid data={summary} />
            </>
          ) : (
            <LinkBox onLink={(newData) => updateLift(id, newData)} />
          )}
          <Stack>
            {(box_id || liftQuery.data.installationControl) && (
              <Card
                to="installation"
                icon={<DialPadIcon />}
                title={t.installationControl}
                disabled={!hasICTRLicense}
              >
                {t.installationControlDescription}
              </Card>
            )}
            {box_id && (
              <>
                <Card
                  // the programming tool button should not be clickable if no valid license is available!
                  to={hasPRGLicense ? '/programming/parameters' : ''}
                  icon={<RemoteIcon />}
                  title={t.programmingTool}
                  disabled={!hasPRGLicense}
                >
                  {t.programmingToolDescription}
                </Card>
                <Card
                  to="realtime/monitoring"
                  icon={<ChartIcon />}
                  title={t.realtimeMonitoringTitle}
                  disabled={!hasRTLicense}
                >
                  {t.realtimeMonitoringDescription}
                </Card>
                {/* <Card
                  to={hasRTLicense ? 'errors' : ''}
                  icon={<WarningIcon />}
                  title={t.errorMessages}
                  disabled={!hasRTLicense}
                >
                  {t.errorMessagesDescription}
                </Card> */}
                <Card
                  to="preventive-maintenance/floor"
                  icon={<PreventiveMaintenanceIcon />}
                  title={t.preventiveMaintenance}
                  disabled={!hasPREVLicense}
                >
                  {t.preventiveMaintenanceDescription}
                </Card>
              </>
            )}
          </Stack>
        </Stack>
      )}
    </Page>
  );
}
