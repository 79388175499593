import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useOffline } from './OfflineProvider';
import { AcceptInvite } from './pages/AcceptInvite';
import { AddBuilding } from './pages/AddBuilding';
import { AddLift } from './pages/AddLift';
import { Admin } from './pages/Admin';
import { AdminCompany } from './pages/AdminCompany';
import { AdminCompanyBoxes } from './pages/AdminCompanyBoxes';
import { AdminCompanyInvites } from './pages/AdminCompanyInvites';
import { AdminCompanyLicenses } from './pages/AdminCompanyLicenses';
import { AdminCompanyUsers } from './pages/AdminCompanyUsers';
import { Building } from './pages/Building';
import { BuildingErrors } from './pages/BuildingErrors';
import { Buildings } from './pages/Buildings';
import { CompanyErrors } from './pages/CompanyErrors';
import { CompanyMembers } from './pages/CompanyMembers';
import { EditBuilding } from './pages/EditBuilding';
import { EditLift } from './pages/EditLift';
import { ForgotPassword } from './pages/ForgotPassword';
import { Installation } from './pages/Installation';
import { History } from './pages/Installation/History';
import { New } from './pages/Installation/New';
import { Restart } from './pages/Installation/Restart';
import { Lift } from './pages/Lift';
import { Login } from './pages/Login';
import { Offline } from './pages/Offline';
import { Component } from './pages/Preventive-Maintenance/Component';
import { ComponentDetails } from './pages/Preventive-Maintenance/ComponentDetails';
import { ComponentsByFloorOverview } from './pages/Preventive-Maintenance/ComponentsByFloorOverview';
import { Overview } from './pages/Preventive-Maintenance/Overview';
import { SelectParts } from './pages/Preventive-Maintenance/SelectParts';
import { Settings } from './pages/Preventive-Maintenance/Settings';
import { Programming } from './pages/Programming';
import { DriveErrors } from './pages/Programming/DriveErrors';
import { DriveParameters } from './pages/Programming/DriveParameters';
import { EditDriveParameter } from './pages/Programming/EditDriveParameter';
import { Realtime } from './pages/Realtime';
import { Errors } from './pages/Realtime/Errors';
import { Monitoring } from './pages/Realtime/Monitoring';
import { ResetPassword } from './pages/ResetPassword';
import { Setup } from './pages/Setup';
import { SharedResults } from './pages/SharedResults';
import { BoxSoftware } from './pages/Software-Update/BoxSoftware';
import { Subscriptions } from './pages/Subscriptions';
import { useUser } from './UserProvider';

const programming = (
  <Route path="/programming" element={<Programming />}>
    <Route path="" element={<Navigate to="parameters" />} />
    <Route path="parameters" element={<DriveParameters />} />
    <Route path="errors" element={<DriveErrors />} />
    <Route path=":paramId" element={<EditDriveParameter />} />
  </Route>
);

export function AppRoutes() {
  const offline = useOffline();
  const user = useUser();

  if (offline) {
    return (
      <Routes>
        {programming}
        <Route path="*" element={<Offline />} />
      </Routes>
    );
  }
  if (!user) {
    return (
      <Routes>
        <Route path="/accept/:token" element={<AcceptInvite />} />
        <Route path="/shared/:id/:key" element={<SharedResults />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="*" element={<Login />} />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path="/" element={<Buildings />} />
      <Route path="/errors" element={<CompanyErrors />} />
      <Route path="/building">
        <Route path="" element={<AddBuilding />} />
        <Route path=":buildingId">
          <Route path="" element={<Building />} />
          <Route path="edit" element={<EditBuilding />} />
          <Route path="errors" element={<BuildingErrors />} />
          <Route path="lift">
            <Route path="" element={<AddLift />} />
            <Route path=":id">
              <Route path="" element={<Lift />} />
              <Route path="edit">
                <Route path="" element={<EditLift />} />
              </Route>
              <Route path="realtime">
                <Route path="" element={<Realtime />} />
                <Route path="monitoring" element={<Monitoring />} />
                <Route path="errors" element={<Errors />} />
              </Route>
              <Route path="installation">
                <Route path="" element={<Installation />} />
                <Route path="new" element={<New />} />
                <Route path="restart" element={<Restart />} />
                <Route path="history" element={<History />} />
              </Route>
              <Route path="preventive-maintenance">
                <Route path="settings">
                  <Route path="" element={<Settings />} />
                  <Route path="component-details">
                    <Route path="" element={<ComponentDetails />} />
                    <Route
                      path=":componentPositionName"
                      element={<SelectParts />}
                    />
                  </Route>
                </Route>
                <Route path="floor">
                  <Route path="" element={<Overview />} />
                  <Route
                    path=":floorId"
                    element={<ComponentsByFloorOverview />}
                  />
                  <Route
                    path=":floorId/component/:componentId"
                    element={<Component />}
                  />
                </Route>
              </Route>
              <Route path="setup" element={<Setup />} />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="/subscriptions" element={<Subscriptions />} />

      <Route path="/shared/:id/:key" element={<SharedResults />} />
      {programming}

      {user.role === 'admin' && (
        <Route path="/admin">
          <Route path="" element={<Admin />} />
          <Route path=":id">
            <Route path="" element={<AdminCompany />} />
            <Route path="users" element={<AdminCompanyUsers />} />
            <Route path="boxes" element={<AdminCompanyBoxes />} />
            <Route path="licenses" element={<AdminCompanyLicenses />} />
            <Route path="invites" element={<AdminCompanyInvites />} />
          </Route>
        </Route>
      )}
      {(user.role === 'admin' || user.role === 'manager') && (
        <Route path="/members" element={<CompanyMembers />} />
      )}
      <Route path="/box-software" element={<BoxSoftware />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
